import { default as _91slug_93TcfAkLMn61Meta } from "/home/forge/www.safiroptik.com.tr/pages/[slug].vue?macro=true";
import { default as addresstI7LHMSX2YMeta } from "/home/forge/www.safiroptik.com.tr/pages/account/address.vue?macro=true";
import { default as _91id_93Wk5uni8iGhMeta } from "/home/forge/www.safiroptik.com.tr/pages/account/orders/[id].vue?macro=true";
import { default as _91id_93aMW015usGHMeta } from "/home/forge/www.safiroptik.com.tr/pages/account/orders/cancel/[id].vue?macro=true";
import { default as indexATDTSeIepFMeta } from "/home/forge/www.safiroptik.com.tr/pages/account/orders/index.vue?macro=true";
import { default as reviewiEvyfznkTaMeta } from "/home/forge/www.safiroptik.com.tr/pages/account/review.vue?macro=true";
import { default as _91id_932196Eo3pCYMeta } from "/home/forge/www.safiroptik.com.tr/pages/account/statement/detail/[id].vue?macro=true";
import { default as indexKDOEUIvp60Meta } from "/home/forge/www.safiroptik.com.tr/pages/account/statement/index.vue?macro=true";
import { default as userikmM9ZwckkMeta } from "/home/forge/www.safiroptik.com.tr/pages/account/user.vue?macro=true";
import { default as wishlistGsZT1uYqJXMeta } from "/home/forge/www.safiroptik.com.tr/pages/account/wishlist.vue?macro=true";
import { default as cartVI7XZb9kIOMeta } from "/home/forge/www.safiroptik.com.tr/pages/cart.vue?macro=true";
import { default as index9TNLvLTl7cMeta } from "/home/forge/www.safiroptik.com.tr/pages/checkout/index.vue?macro=true";
import { default as payment1hfHLe8QH4Meta } from "/home/forge/www.safiroptik.com.tr/pages/checkout/payment.vue?macro=true";
import { default as summary_46clientNDlxuQURc3Meta } from "/home/forge/www.safiroptik.com.tr/pages/checkout/summary.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/forge/www.safiroptik.com.tr/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as requestesLLkv3R0EMeta } from "/home/forge/www.safiroptik.com.tr/pages/customer/request.vue?macro=true";
import { default as successetF5HREb2ZMeta } from "/home/forge/www.safiroptik.com.tr/pages/customer/success.vue?macro=true";
import { default as iletisim1bTrHlTPp9Meta } from "/home/forge/www.safiroptik.com.tr/pages/iletisim.vue?macro=true";
import { default as index4uYQyTEM5XMeta } from "/home/forge/www.safiroptik.com.tr/pages/index.vue?macro=true";
import { default as loginaRiF8JPkd0Meta } from "/home/forge/www.safiroptik.com.tr/pages/login.vue?macro=true";
import { default as offerS5AVjZSehoMeta } from "/home/forge/www.safiroptik.com.tr/pages/offer.vue?macro=true";
import { default as statusMWnsHiudy5Meta } from "/home/forge/www.safiroptik.com.tr/pages/order/status.vue?macro=true";
import { default as trackFMNagiorZZMeta } from "/home/forge/www.safiroptik.com.tr/pages/order/track.vue?macro=true";
import { default as forgottett7n1oaxMeta } from "/home/forge/www.safiroptik.com.tr/pages/password/forgot.vue?macro=true";
import { default as resetY1NHW6fk5LMeta } from "/home/forge/www.safiroptik.com.tr/pages/password/reset.vue?macro=true";
import { default as payment_45notificationK3dtjSstwAMeta } from "/home/forge/www.safiroptik.com.tr/pages/payment-notification.vue?macro=true";
import { default as summaryPPiTjbAP5OMeta } from "/home/forge/www.safiroptik.com.tr/pages/payment/summary.vue?macro=true";
import { default as registerdleRiBfvAbMeta } from "/home/forge/www.safiroptik.com.tr/pages/register.vue?macro=true";
import { default as search81dNQ0TFx6Meta } from "/home/forge/www.safiroptik.com.tr/pages/search.vue?macro=true";
import { default as locationsKbKUYf8YqVMeta } from "/home/forge/www.safiroptik.com.tr/modules/locationsPage/pages/locations.vue?macro=true";
import { default as component_45stub6Itg2UR9R5Meta } from "/home/forge/www.safiroptik.com.tr/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub6Itg2UR9R5 } from "/home/forge/www.safiroptik.com.tr/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___tr",
    path: "/:slug()",
    meta: _91slug_93TcfAkLMn61Meta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/[slug].vue")
  },
  {
    name: "account-address___tr",
    path: "/account/address",
    meta: addresstI7LHMSX2YMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/account/address.vue")
  },
  {
    name: "account-orders-id___tr",
    path: "/account/orders/:id()",
    meta: _91id_93Wk5uni8iGhMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders-cancel-id___tr",
    path: "/account/orders/cancel/:id()",
    meta: _91id_93aMW015usGHMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/account/orders/cancel/[id].vue")
  },
  {
    name: "account-orders___tr",
    path: "/account/orders",
    meta: indexATDTSeIepFMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/account/orders/index.vue")
  },
  {
    name: "account-review___tr",
    path: "/account/review",
    meta: reviewiEvyfznkTaMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/account/review.vue")
  },
  {
    name: "account-statement-detail-id___tr",
    path: "/account/statement/detail/:id()",
    meta: _91id_932196Eo3pCYMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/account/statement/detail/[id].vue")
  },
  {
    name: "account-statement___tr",
    path: "/account/statement",
    meta: indexKDOEUIvp60Meta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/account/statement/index.vue")
  },
  {
    name: "account-user___tr",
    path: "/account/user",
    meta: userikmM9ZwckkMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/account/user.vue")
  },
  {
    name: "account-wishlist___tr",
    path: "/account/wishlist",
    meta: wishlistGsZT1uYqJXMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/account/wishlist.vue")
  },
  {
    name: "cart___tr",
    path: "/cart",
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/cart.vue")
  },
  {
    name: "checkout___tr",
    path: "/checkout",
    meta: index9TNLvLTl7cMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___tr",
    path: "/checkout/payment",
    meta: payment1hfHLe8QH4Meta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/checkout/payment.vue")
  },
  {
    name: "checkout-summary___tr",
    path: "/checkout/summary",
    component: () => createClientPage(() => import("/home/forge/www.safiroptik.com.tr/pages/checkout/summary.client.vue"))
  },
  {
    name: "customer-request___tr",
    path: "/customer/request",
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/customer/request.vue")
  },
  {
    name: "customer-success___tr",
    path: "/customer/success",
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/customer/success.vue")
  },
  {
    name: "iletisim___tr",
    path: "/iletisim",
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/iletisim.vue")
  },
  {
    name: "index___tr",
    path: "/",
    meta: index4uYQyTEM5XMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/index.vue")
  },
  {
    name: "login___tr",
    path: "/login",
    meta: loginaRiF8JPkd0Meta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/login.vue")
  },
  {
    name: "offer___tr",
    path: "/offer",
    meta: offerS5AVjZSehoMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/offer.vue")
  },
  {
    name: "order-status___tr",
    path: "/order/status",
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/order/status.vue")
  },
  {
    name: "order-track___tr",
    path: "/order/track",
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/order/track.vue")
  },
  {
    name: "password-forgot___tr",
    path: "/password/forgot",
    meta: forgottett7n1oaxMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/password/forgot.vue")
  },
  {
    name: "password-reset___tr",
    path: "/password/reset",
    meta: resetY1NHW6fk5LMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/password/reset.vue")
  },
  {
    name: "payment-notification___tr",
    path: "/payment-notification",
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/payment-notification.vue")
  },
  {
    name: "payment-summary___tr",
    path: "/payment/summary",
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/payment/summary.vue")
  },
  {
    name: "register___tr",
    path: "/register",
    meta: registerdleRiBfvAbMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/register.vue")
  },
  {
    name: "search___tr",
    path: "/search",
    meta: search81dNQ0TFx6Meta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/search.vue")
  },
  {
    name: "contact___tr",
    path: "/magazalarimiz",
    component: () => import("/home/forge/www.safiroptik.com.tr/modules/locationsPage/pages/locations.vue")
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5503-c4152-seffaf-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5005-c0707-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5053-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5507-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5506-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5506-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5503-c4107-seffaf-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5502-c4107-seffaf-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5502-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5521-c3737-ten",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5533-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5533-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5501-c4152-seffaf-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5501-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5050-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5006-c4901-kirmizi-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5005-c5252-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5005-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5004-c4107-seffaf-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5004-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5004-c4007-fume-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5004-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5004-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5514-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5514-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5005-c5624-mavi",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5004-c5624-mavi",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5504-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5502-c4152-seffaf-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5533-c6110-yesil",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5533-c4401-kahve-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5512-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5512-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5507-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5505-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5026-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5026-c4107-seffaf-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5026-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5026-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5002-c5252-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5532-c4401-kahve-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5532-c4007-fume-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5532-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5532-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5532-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5532-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5050-c5252-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5050-c4614-pembe",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5050-c4107-seffaf-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5050-c0707-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5006-c4614-pembe",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5005-c4107-seffaf-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5005-c4614-pembe",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5003-c5624-mavi",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5003-c5252-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5531-c4401-kahve-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5531-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5530-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5529-c4141-seffaf",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5529-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5527-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5522-c4141-seffaf",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5521-c4141-seffaf",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5053-c4141-seffaf",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5030-c4141-seffaf",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5531-c5401-lacivert-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5531-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5531-c4007-fume-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5531-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5531-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5530-c5401-lacivert-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5530-c4401-kahve-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5530-c4007-fume-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5530-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5530-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5530-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5529-c5401-lacivert-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5529-c4401-kahve-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5529-c4007-fume-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5529-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5529-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5527-c5401-lacivert-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5527-c4401-kahve-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5527-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5527-c4007-fume-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5527-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5527-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5526-c5401-lacivert-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5526-c4401-kahve-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5526-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5526-c4007-fume-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5526-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5526-c0401-kirmizi-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5526-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5526-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5525-c5401-lacivert-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5525-c4401-kahve-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5525-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5525-c4007-fume-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5525-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5525-c0401-kirmizi-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5525-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5525-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5524-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5524-c5205-gul-kurusu-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5524-c4141-seffaf",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5524-c3737-ten",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5524-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5524-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5523-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5523-c5205-gul-kurusu-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5523-c4141-seffaf",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5523-c3737-ten",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5523-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5523-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5522-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5522-c5205-gul-kurusu-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5522-c3737-ten",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5522-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5521-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5521-c5205-gul-kurusu-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5521-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5521-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5520-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5520-c5205-gul-kurusu-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5520-c4141-seffaf",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5520-c3737-ten",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5520-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5520-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5519-c6101-yesil-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5519-c6060-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5519-c5252-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5519-c4401-kahve-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5519-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5519-c3301-koyu-lila-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5519-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5519-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5518-c6101-yesil-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5518-c6060-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5518-c5252-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5518-c4401-kahve-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5518-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5518-c3301-koyu-lila-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5518-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5518-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5517-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5517-c5401-lacivert-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5517-c5252-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5517-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5517-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5517-c2401-turkuaz-yesili-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5517-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5517-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5517-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5516-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5516-c3737-ten",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5516-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5516-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5515-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5515-c3737-ten",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5515-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5515-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5514-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5514-c5401-lacivert-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5514-c5252-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5514-c1362-turuncu-kahve",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5514-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5514-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5514-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5513-c6301-mor-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5513-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5513-c5205-gul-kurusu-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5513-c4401-kahve-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5513-c4141-seffaf",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5513-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5513-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5513-c3737-ten",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5513-c0108-siyah-yesil",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5513-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5513-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5513-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5512-c5401-lacivert-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5512-c5252-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5512-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5511-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5511-c5401-lacivert-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5511-c5252-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5511-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5511-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5511-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5511-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5511-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5510-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5510-c5205-gul-kurusu-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5510-c3737-ten",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5510-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5510-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5509-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5509-c5401-lacivert-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5509-c5252-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5509-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5509-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5509-c1362-turuncu-kahve",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5509-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5509-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5509-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5508-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5508-c5401-lacivert-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5508-c5252-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5508-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5508-c0401-kirmizi-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5508-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5508-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5508-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5507-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5507-c5401-lacivert-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5507-c5252-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5507-c1362-turuncu-kahve",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5507-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5507-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5507-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5506-c6101-yesil-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5506-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5506-c5401-lacivert-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5506-c5252-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5506-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5506-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5506-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5505-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5505-c5401-lacivert-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5505-c5252-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5505-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5505-c2401-turkuaz-yesili-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5505-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5505-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5505-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5504-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5504-c5401-lacivert-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5504-c5252-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5504-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5504-c0401-kirmizi-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5504-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5504-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5504-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5503-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5503-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5503-c4007-fume-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5503-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5503-c0707-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5503-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5503-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5503-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5502-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5502-c4007-fume-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5502-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5502-c0707-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5502-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5502-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5502-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5501-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5501-c4107-seffaf-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5501-c4007-fume-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5501-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5501-c0707-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5501-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5501-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5501-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5053-c5205-gul-kurusu-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5053-c4401-kahve-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5053-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5053-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5050-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5050-c4901-kirmizi-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5050-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5030-c5401-lacivert-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5030-c4401-kahve-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5030-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5026-c5252-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5026-c4901-kirmizi-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5026-c4614-pembe",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5026-c4007-fume-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5026-c0707-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5026-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5006-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5006-c5252-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5006-c4007-fume-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5006-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5006-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5005-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5005-c4901-kirmizi-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5005-c4007-fume-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5005-c4001-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5005-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5004-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5004-c5252-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5004-c4901-kirmizi-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5004-c4614-pembe",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5004-c0707-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5004-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5003-c4107-seffaf-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5003-c4101-seffaf-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5003-c0707-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5003-c0107-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5003-c0105-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5003-c0101-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5002-c6001-bordo-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5002-c5624-mavi",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5002-c4901-kirmizi-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5002-c4107-seffaf-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5002-c0707-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1031-c40-siyah-kirmizi",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1031-c212-mor",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1031-c190-fusya-beyaz",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1031-c129-gul-kurusu-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1031-c126-lila-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1031-c123-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1031-c108-kirmizi-beyaz",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1031-c106-siyah-beyaz",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1030-c170-lacivert-turuncu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1029-c119-siyah-sari",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1031-c107-seffaf-kirmizi",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1029-c170-lacivert-turuncu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1029-c123-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1029-c106-siyah-beyaz",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1029-c01-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1028-c170-lacivert-turuncu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1028-c119-siyah-sari",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1028-c106-siyah-beyaz",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1031-c119-siyah-sari",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1031-c19-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1030-c123-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1030-c119-siyah-sari",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1030-c106-siyah-beyaz",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1030-c19-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1030-c01-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1029-c19-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1028-c123-siyah-lacivert",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/gl1028-c19-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/3010-c03m-mat-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/3007-c75m-mat-kahve-demi",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/3013-c46m-mat-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/3013-c17m-mat-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/3013-c03m-mat-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/3012-c03m-mat-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/3011-c46m-mat-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/3011-c17m-mat-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/3011-c03m-mat-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/3009-c03m-mat-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/3008-c46m-mat-siyah-bordo",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/3008-c17m-mat-fume-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/3008-c03m-mat-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/3007-c03m-mat-siyah",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/3006-c03m-mat-siyah",
    component: component_45stub6Itg2UR9R5
  }
]